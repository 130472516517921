import { TaskTemplateListView } from "./taskTemplateListView"

export class TaskSideBarModel {
  internals: TaskTemplateListView[] = []
  externals: TaskTemplateListView[] = []
  sharedBy: TaskTemplateListView[] = []
  /*
  constructor(data: {internals:TaskTemplateListView[], externals:TaskTemplateListView[]}) {
    this.internals = data.internals
    this.externals = data.externals
  }
*/
}
