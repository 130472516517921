export enum NotificationTopics {
  NEW_MESSAGE = "NEW_MESSAGE",
  INTERESTED_IN_TASK = "INTRESTED_IN_TASK",
  NOT_INTERESTED_IN_TASK = "NOT_INTRESTED_IN_TASK",
  NEW_CONTRACT = "NEW_CONTRACT",
  CONTRACT_REJECTED = "CONTRACT_REJECTED",
  CONTRACT_APPROVED = "CONTRACT_APPROVED",
  CONTRACT_REQUEST = "CONTRACT_REQUEST",
  TALENT_ADDED = "TALENT_ADDED",
  RFQ_SENT = "RFQ_SEND",
  TALENT_UPDATED = "TALENT_UPDATED",
}
