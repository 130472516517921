export class TaskTemplateListView {
  templateId: string = ""
  templateName: string = ""
  ownerId: string = ""
  ownerName: string = ""
  numTasks: number = 0
  numInterested: number = 0
  /*
  constructor(uid: string, name: string, num: number) {
    this.uid = uid
    this.name = name
    this.num = num
  }

  static fromJSON(json: any): TaskTemplateListView {
    return new TaskTemplateListView(json.uid, json.name, 0)
  }
*/

  constructor(data: {
    templateid: string
    templatename: string
    ownerid: string
    ownername: string
    numtasks: number
    numinterested: number
  }) {
    this.templateId = data["templateid"]
    this.templateId = data.templateid
    this.templateName = data.templatename
    this.ownerId = data.ownerid
    this.ownerName = data.ownername
    this.numTasks = data.numtasks
    this.numInterested = data.numinterested
  }

  static fromJSON(json: any): TaskTemplateListView {
    //    return new TaskTemplateListView(json["templateId"], json.templateName, json.ownerId, json.ownerName, json.numTasks, json.numInterested)
    return new TaskTemplateListView(json)
  }
}
