import { cn } from "@/utils/helpers"
import { cva, type VariantProps } from "class-variance-authority"
import { forwardRef } from "react"

const tagVariants = cva("flex rounded-full items-center box-border", {
  variants: {
    variant: {
      neutral:
        "bg-neutral-container text-on-neutral-container border-on-neutral-container [&>div]:bg-on-neutral-container",
      positive:
        "bg-success-container text-on-success-container border-on-success-container [&>div]:bg-on-success-container",
      warning:
        "bg-warning-container text-on-warning-container border-on-warning-container [&>div]:bg-on-warning-container",
      negative:
        "bg-error-container text-on-error-container border-on-error-container [&>div]:bg-on-error-container",
    },
    size: {
      sm: "h-6 px-3 text-xs",
      md: "h-7 px-3 text-sm",
      lg: "h-8 px-4 text-base",
    },
    hasOutline: {
      true: "border bg-transparent",
      false: "border-0",
    },
  },
  defaultVariants: {
    variant: "neutral",
    size: "sm",
    hasOutline: false,
  },
})

export interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tagVariants> {
  label: string
  hasBadge?: boolean
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  (
    { label, className, variant, size, hasOutline, hasBadge, ...props },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(tagVariants({ variant, size, hasOutline }), className)}
        {...props}
      >
        {hasBadge && (
          <div className={cn("-ml-1 mr-1.5 h-1.5 w-1.5 rounded-full")} />
        )}
        {label}
      </div>
    )
  },
)
Tag.displayName = "Tag"

export { Tag }
