import { TaskSideBarModel } from "../models/taskSideBarModel"
import { TaskTemplateListView } from "../models/taskTemplateListView"

export class TemplateFactory {
  static parse<T>(data: any, ctor: new (args: any) => T): T {
    return new ctor(data)
  }

  static parseListView(jsonArray: any[]): TaskTemplateListView[] {
    //    return jsonArray.map((json) => TaskTemplateListView.fromJSON(json))
    return jsonArray.map((json) =>
      TemplateFactory.parse(json, TaskTemplateListView),
    )
  }

  static parseTaskSideBarModel(jsonData: any): TaskSideBarModel {
    const taskSideBarModel = new TaskSideBarModel()

    if (Array.isArray(jsonData.internals)) {
      taskSideBarModel.internals = jsonData.internals.map((item: any) => {
        return TemplateFactory.parse(item, TaskTemplateListView)
        /*  new TaskTemplateListView({
                templateId: item.templateId,
                templateName: item.templateName,
                ownerId: item.ownerId,
                ownerName: item.ownerName,
                numTasks: item.numTasks,
                numInterested: item.numInterested
            })
                */
      })
    }

    if (Array.isArray(jsonData.externals)) {
      taskSideBarModel.externals = jsonData.externals.map((item: any) => {
        return TemplateFactory.parse(item, TaskTemplateListView)
        /*  new TaskTemplateListView({
                templateId: item.templateId,
                templateName: item.templateName,
                ownerId: item.ownerId,
                ownerName: item.ownerName,
                numTasks: item.numTasks,
                numInterested: item.numInterested
            })
                */
      })
    }

    if (Array.isArray(jsonData.sharedby)) {
      taskSideBarModel.sharedBy = jsonData.sharedby.map((item: any) => {
        return TemplateFactory.parse(item, TaskTemplateListView)
      })
    }

    return taskSideBarModel
  }
}
