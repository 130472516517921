import { IExtendedTemplate } from "@/features/templates/types"
import { fetcher } from "@/utils/api"
import { injectable } from "tsyringe"
import { ITask } from "../types/itask"
import { TemplateFactory } from "../factories/templateFactory"
//import { TaskTemplateListView } from "../models/taskTemplateListView"
import { TaskSideBarModel } from "../models/taskSideBarModel"

const TASK_TEMPLATE_APP = "52e7c717-d7fe-48fb-8916-61b8fea4ecbb"

@injectable()
export class TaskRepository {
  constructor() {}

  async getTasksAll(
    state: string,
    templateId: string = "",
    templateType: string = "",
  ): Promise<ITask[]> {
    const url: string = `/tasks${state ? `?state=${state}&templateId=${templateId}&templateType=${templateType}` : ""}`
    const tasks: ITask[] = await fetcher(url)
    return tasks
  }

  async getTasks(state: string, skip: number, num: number): Promise<ITask[]> {
    const url: string = `/tasks${state ? `?state=${state}&skip=${skip}&num=${num}` : ""}`
    const tasks: ITask[] = await fetcher(url)
    return tasks
  }

  async getExtendedTemplate(templateId: string): Promise<IExtendedTemplate> {
    const url: string = `/apps/${TASK_TEMPLATE_APP}/data/templates/${templateId}`
    const template: IExtendedTemplate = await fetcher(url)
    return template
  }
  /*
  async getSidebarTemplates(): Promise<TaskTemplateListView[]> {
    const url: string = `/tasks/sidebar`
//    const result: any[] = await fetcher(url)
    const model:TaskSideBarModel = await fetcher(url)
    const templates: TaskTemplateListView[] =
      TemplateFactory.parseListView(model.internals)
    return templates
  }
*/
  async getSidebarTemplates(): Promise<TaskSideBarModel> {
    const url: string = `/tasks/sidebar`
    //    let model:TaskSideBarModel = await fetcher(url)
    //    model.internals = TemplateFactory.parseListView(model.internals)
    //    model.externals = TemplateFactory.parseListView(model.externals)
    const jsonData: any = await fetcher(url)
    //    let model:TaskSideBarModel = TemplateFactory.parse(jsonData, TaskSideBarModel)
    const model: TaskSideBarModel =
      TemplateFactory.parseTaskSideBarModel(jsonData)

    return model
  }

  async updateTask(taskId: string, payload: Record<string, any>) {
    return await fetcher(`/tasks/${taskId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
  }

  async toggleInterest(
    uid: string,
    state: boolean,
    feedback?: { reason: string; feedback?: string },
  ): Promise<boolean> {
    return fetcher(`/tasks/${uid}/interest`, {
      method: "POST",
      body: JSON.stringify({ state, ...feedback }),
    })
  }
}
